@import 'src/styles/vars';

.imageContainer {
    position: absolute;
    overflow: hidden;
    width: 60%;
    height: 100%;
    left: 40%;
    z-index: -1;
}

.content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: 30%;
    flex-direction: column;
}

.glow:hover {
    //animation: glowing 1s ease-in-out infinite alternate;
}

.cat {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    transform: translate(-100px, -40px) rotate(-20deg);
    z-index: 0;
}

.woman {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    transform: translate(-30px, 40px) rotate(-20deg);
    z-index: 0;
}

.conferenceEventContainer {
    position: relative;
}

.instantMeetingContainer {
    position: relative;
}

@keyframes glowing {
    from {
        box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #f0f, 0 0 40px #0ff,
            0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        box-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
            0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
            0 0 80px #ff4da6;
    }
}
