$default-padding: var(--space-xs);
$default-padding-3xsmall: var(--space-3xs);
$default-padding-2xsmall: var(--space-2xs);
$default-padding-xsmall: var(--space-xs);
$default-padding-small: var(--space-s);
$default-padding-medium: var(--space-m);
$default-padding-large: var(--space-l);
$default-padding-left: 0.5rem;
$default-padding-right: 0.5rem;
$default-padding-top: 0.1rem;
$default-padding-bottom: 0.1rem;

$default-margin-3xsmall: var(--space-3xs);
$default-margin-2xsmall: var(--space-2xs);
$default-margin-xsmall: var(--space-xs);
$default-margin: var(--space-xs);
$default-margin-small: var(--space-s);
$default-margin-medium: var(--space-m);
$default-margin-large: var(--space-l);

$default-gap-3xsmall: $default-margin-3xsmall;
$default-gap-2xsmall: $default-margin-2xsmall;
$default-gap-xsmall: $default-margin;
$default-gap-small: $default-margin-small;
$default-gap-medium: $default-margin-medium;
$default-gap-large: $default-margin-large;

$default-component-height: 2rem;

$default-border-width: 4px;
$default-border-color: gray;
$default-border-radius: 3px;

/**
 * Old color pallet
 * Code name: Aang
 */
$theme-colors: (
    'pallet-color-0': #9b51e0,
    'pallet-color-1': #54478c,
    'pallet-color-2': #2c699a,
    'pallet-color-3': #048ba8,
    'pallet-color-4': #0db39e,
    'pallet-color-5': #16db93,
    'pallet-color-6': #83e377,
    'pallet-color-7': #b9e769,
    'pallet-color-8': #efea5a,
    'pallet-color-9': #f1c453,
    'pallet-color-10': #f29e4c
);

/**
 * New color pallet
 * Code name: Bird
 */
$theme-colors-bird: (
    'pallet-bg-common': #393c42f2,
    'pallet-bg-light': #828f9b,
    'pallet-bg-accent': #22262a,
    'pallet-bg-warning': #da0063,
    'pallet-font-color-soft': #272c2f
);

$theme-bird-bg-soft-color: map-get(
    $theme-colors-bird,
    'pallet-font-color-soft'
);
$theme-bird-bg-common-color: map-get($theme-colors-bird, 'pallet-bg-common');
$theme-bird-bg-light-color: map-get($theme-colors-bird, 'pallet-bg-light');
$theme-bird-bg-accent-color: map-get($theme-colors-bird, 'pallet-bg-accent');
$theme-bird-bg-warning-color: map-get($theme-colors-bird, 'pallet-bg-warning');

$theme-bird-font-common-color: map-get(
    $theme-colors-bird,
    'pallet-font-color-soft'
);

/***************************************
 * CSS BreakPoints for mobile
 ***************************************/

$mobile-breakpoint-width: 950px;
$mobile-breakpoint-width-video: 760px;

$default-accent-color: map-get($theme-colors, 'pallet-color-0');
$default-warning-color: #da0063;
$default-success-color: #0ca789;
$default-disabled-color: #808080;
$default-not-accent-color: #ddd;
$default-critical-color: darkred;

$default-hover-color: map-get($theme-colors, 'pallet-color-10');
$default-shadow-color: map-get($theme-colors, 'pallet-color-1');

$default-font-gigantic: var(--step-5);
$default-font-h1: var(--step-4);
$default-font-h2: var(--step-3);
$default-font-h3: var(--step-2);
$default-font-h4: var(--step-1);
$default-font-h5: var(--step-0);
$default-font-text: var(--step--1);
$default-font-caption: var(--step--2);

//Deprecated
$default-font-small: $default-font-caption;

$default-font-headings-weight: 300;

$default-background-blur: blur(10px);
$default-background-pattern-color: white;
$default-background-weave-color: lightgray;

$user-controls-default-height: 60px;
$call-control-size: 48px;
/***************************************
 * CSS Mixins
 ***************************************/
@mixin clickable-element {
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.95);
    }
}

@mixin button-block {
    padding: $default-padding-small;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: $default-border-radius;

    //&:hover {
    //    box-shadow: 3px 2px 30px 10px rgba(0, 0, 0, 0.24);
    //}
    //
    //&:active {
    //    transform: scale(0.95);
    //    box-shadow: 3px 2px 30px 1px rgba(0, 0, 0, 0.24);
    //}
}

@mixin menu-block {
    display: grid;

    .linkContainer {
        @include button-block;
    }
}

/***************************************
 * Animations
 ***************************************/
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
